dmx.Component('bs5-modal', {

    attributes: {
        nobackdrop: {
            type: Boolean,
            default: false
        },

        nocloseonclick: {
            type: Boolean,
            default: false
        },

        nokeyboard: {
            type: Boolean,
            default: false
        },

        nofocus: {
            type: Boolean,
            default: false
        },

        show: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        toggle: function() {
            this.instance.toggle();
        },

        show: function() {
            this.instance.show();
        },

        hide: function() {
            this.instance.hide();
        },

        update: function() {
            this.instance.handleUpdate();
        }
    },

    events: {
        show: Event,
        shown: Event,
        hide: Event,
        hidden: Event
    },

    render: function(node) {
        this.$node = node;
        this.$parse();

        this.$node.addEventListener('show.bs.modal', this.dispatchEvent.bind(this, 'show'));
        this.$node.addEventListener('shown.bs.modal', this.dispatchEvent.bind(this, 'shown'));
        this.$node.addEventListener('hide.bs.modal', this.dispatchEvent.bind(this, 'hide'));
        this.$node.addEventListener('hidden.bs.modal', this.dispatchEvent.bind(this, 'hidden'));

        this.update({});
    },

    update: function(props) {
        if (['nobackdrop', 'nocloseonclick', 'nokeyboard', 'nofocus'].some(function(prop) { return props[prop] != this.props[prop] }, this)) {
            if (this.instance) {
                this.instance.dispose();
                delete this.instance;
            }

            this.instance = new bootstrap.Modal(this.$node, {
                backdrop: (!this.props.nobackdrop && !!this.props.nocloseonclick ? 'static' : !this.props.nobackdrop),
                keyboard: !this.props.nokeyboard,
                focus: !this.props.nofocus
            });
        }

        if (props.show != this.props.show) {
            if (this.props.show) {
                this.instance.show();
            } else {
                this.instance.hide();
            }
        }
    }

});
